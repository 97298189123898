/**
 * DOM Helper
 * Browser support: All modern browser and IE8+
 *
 * v1.1.0
 */
var DOMHelper = function() {
    'use strict';


    /**
     * Ready
     *
     * Call function when DOM content is ready
     */
    var ready = function(fn) {
        if (document.readyState != 'loading') {
            fn();
        } else if (document.addEventListener) {
            document.addEventListener('DOMContentLoaded', fn);
        } else {
            // IE8 only
            document.attachEvent('onreadystatechange', function() {
                if (document.readyState != 'loading') {
                    fn();
                }
            });
        }
    };


    /**
     * Select
     *
     * example selector: '.my #awesome selector'
     */
    var select = function(selector, root) {
        root = root || document;
        return root.querySelectorAll(selector);
    };


    /**
     * Use or select
     *
     * use element or select element by string
     */
    var use_or_select = function(selectorOrElement) {
        if (typeof selectorOrElement === 'string') {
            return select(selectorOrElement)[0];
        }
        return selectorOrElement;
    };


    /**
     * Add class
     */
    var add_class = function(el, className) {
        el = use_or_select(el);

        if (el.classList) {
            el.classList.add(className);
        }
        else {
            el.className += ' ' + className;
        }
    };


    /**
     * Remove class
     */
    var remove_class = function(el, className) {
        el = use_or_select(el);

        if (el.classList) {
            el.classList.remove(className);
        } else {
            el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };


    /**
     * Has class
     */
    var has_class = function(el, className) {
        el = use_or_select(el);

        if (el.classList) {
            return el.classList.contains(className);
        }
        else {
            return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
        }
    };


    /**
     * Toggle class
     */
    var toggle_class = function(el, className) {
        el = use_or_select(el);

        if (el.classList) {
            el.classList.toggle(className);
        } else {
            var classes = el.className.split(' ');
            var existingIndex = -1;
            for (var i = classes.length; i--;) {
                if (classes[i] === className){
                    existingIndex = i;
                }
            }

            if (existingIndex >= 0) {
                classes.splice(existingIndex, 1);
            }
            else {
                classes.push(className);
            }

            el.className = classes.join(' ');
        }
    };


    /**
     * Add event listenter
     */
    var addEventListener = function(el, eventName, handler) {
        el = use_or_select(el);

        if (typeof el === 'undefined') {
            // element not found
            return false;
        }

        if (el.addEventListener) {
            el.addEventListener(eventName, handler);
        } else {
            el.attachEvent('on' + eventName, function() {
                handler.call(el);
            });
        }
    };


    /**
     * Public
     */
    return {
        ready: ready,
        select: select,
        useOrSelect: use_or_select,
        addClass: add_class,
        removeClass: remove_class,
        hasClass: has_class,
        toggleClass: toggle_class,
        addEventListener: addEventListener
    };

};


// Create DOMHelper object
window.dh = window.dh || new DOMHelper();
console.log('DOMHelper');   //, dh);
