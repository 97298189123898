/**
 * Main Javascript File
 *
 * v1.0.0
 */
$ = jQuery.noConflict();

var mainScript = function () {
    'use strict';


    /**
     * init page
     */
    var init = function () {

        var map = null;

        $('html').removeClass('no-js');

        // enable SVG use tag for legacy browsers
        svg4everybody();

        //cookie_law();

        // Fastclick
        init_fastclick();

        // Init Slider
        init_slider();
        init_slider_content();

        // Scroll Function
        scroll_to_position();

        // Auto Hiding Navigation
        //auto_hiding_navigation();

        // Checkout Collapsable Boxes
        collapsable_boxes();

        // Init Sidebar Functionality
        init_navigation();

        // Google Map
        init_maps();

        console.log('main.js: page initialized');


    };

    /**
     * Cookie Consent
     */
    var cookie_law = function () {

        window.addEventListener("load", function () {
            window.cookieconsent.initialise({
                theme: "custom",
                position: "bottom-right",
                revokable: true,
                onStatusChange: function (status) {
                    console.log(this.hasConsented() ?
                        'enable cookies' : 'disable cookies');
                },
                elements: {
                    dismiss: '<a aria-label="dismiss cookie message" tabindex="0" class="btn btn-sm btn-primary cc-btn cc-dismiss">{{dismiss}}</a>',
                }
            })
        });

    };


    /**
     * Fast Click
     */
    var init_fastclick = function () {

        $(function () {
            FastClick.attach(document.body);
        });

    }

    /**
     * Init Sidebars
     */
    var init_navigation = function () {

        $('.hamburger, .navbar-backdrop').click(function () {

            $('.hamburger').toggleClass('is-active not-active');
            $('body').toggleClass('nav-xs-hidden nav-xs-show');

            return false;
        });

    };


    /**
     * Slider
     */
    var init_slider = function () {

        var el = dh.select('.slider');
        if (el.length === 0) {
            // No slider on this page --> skip
            return null;
        }

        var sliders = [];

        $('.slider').each(function (index, element) {
            $(this).addClass('sw' + index);
            //$(this).find('.swiper-button-prev').addClass('sw-prev' + index);
            //$(this).find('.swiper-button-next').addClass('sw-next' + index);
            $(this).find('.swiper-pagination').addClass('sw-pagination' + index);

            var mySwiper = new Swiper('.sw' + index, {
                speed: 500,
                spaceBetween: 0,
                effect: 'fade',
                autoplay: 3000,
                grabCursor: true,
                keyboardControl: false,
                loop: true,
                pagination: '.sw-pagination' + index,
                paginationClickable: true,
                //prevButton: '.sw-prev' + index,
                //nextButton: '.sw-next' + index,
                preloadImages: false,
                lazyLoading: true,
                lazyLoadingInPrevNext: true,
                lazyLoadingInPrevNextAmount: 2
            });

            sliders.push(mySwiper);

        });

    };

    var init_slider_content = function () {

        var el = dh.select('.slider-content');
        if (el.length === 0) {
            // No slider on this page --> skip
            return null;
        }

        var sliders = [];

        $('.slider-content').each(function (index, element) {
            $(this).addClass('swc' + index);
            $(this).find('.button-prev').addClass('swc-prev' + index);
            $(this).find('.button-next').addClass('swc-next' + index);
            $(this).find('.swiper-pagination').addClass('swc-pagination' + index);

            var mySwiper1 = new Swiper('.swc' + index, {
                speed: 500,
                spaceBetween: 0,
                effect: 'fade',
                autoplay: 3000,
                autoHeight: true,
                grabCursor: true,
                keyboardControl: false,
                loop: true,
                pagination: '.swc-pagination' + index,
                paginationType: 'fraction',
                paginationClickable: true,
                prevButton: '.swc-prev' + index,
                nextButton: '.swc-next' + index,
                preloadImages: false,
                lazyLoading: true,
                lazyLoadingInPrevNext: true,
                lazyLoadingInPrevNextAmount: 2
            });

            sliders.push(mySwiper1);

        });

    };

    /**
     * Init Scroll To
     */
    var scroll_to_position = function () {

        $('.scroll-to').on('click', function () {

            var id = $(this).attr('href');

            $('body, html').animate({
                scrollTop: $(id).offset().top
            }, 1000, 'easeInOutExpo');

            return false
        });

    };


    /**
     * Collapse Boxes
     */
    var collapsable_boxes = function () {

        if ($('.collapse').length > 0)
            return false;

        $('.collapse').collapse();

    };


    /**
     * Auto Hiding Navigation
     */
    var auto_hiding_navigation = function () {


        var mainHeader = $('.navbar-main'),
            secondaryNavigation = $('.navbar-subbb'),
            navDrop = $('.nav-drop'),
            //this applies only if secondary nav is below intro section
            belowNavHeroContent = $('.sub-nav-hero'),
            headerHeight = mainHeader.height();

        //set scrolling variables
        var scrolling = false,
            previousTop = 0,
            currentTop = 0,
            scrollDelta = 10,
            scrollOffsetNav = 15,
            scrollOffset = 150;

        mainHeader.on('click', '.nav-trigger', function (event) {
            // open primary navigation on mobile
            event.preventDefault();
            mainHeader.toggleClass('nav-open');
        });

        $(window).on('scroll', function () {
            if (!scrolling) {
                scrolling = true;
                (!window.requestAnimationFrame)
                    ? setTimeout(autoHideHeader, 250)
                    : requestAnimationFrame(autoHideHeader);
            }
        });

        $(window).on('resize', function () {
            headerHeight = mainHeader.height();
        });

        function autoHideHeader() {
            var currentTop = $(window).scrollTop();

            ( belowNavHeroContent.length > 0 )
                ? checkStickyNavigation(currentTop) // secondary navigation below intro
                : checkSimpleNavigation(currentTop);

            previousTop = currentTop;
            scrolling = false;
        }

        function checkSimpleNavigation(currentTop) {
            //there's no secondary nav or secondary nav is below primary nav

            /*
             if (previousTop - currentTop > scrollDelta) {
             //if scrolling up...
             mainHeader.removeClass('is-hidden');
             } else if( currentTop - previousTop > scrollDelta && currentTop > scrollOffset) {
             //if scrolling down...
             mainHeader.addClass('is-hidden');
             }

             */
            if (currentTop > scrollOffsetNav) {
                mainHeader.addClass('shrink-nav');
                navDrop.addClass('shrink-nav');

            } else if (currentTop <= scrollOffsetNav) {
                mainHeader.removeClass('shrink-nav');
                navDrop.removeClass('shrink-nav');

            }

        }

        function checkStickyNavigation(currentTop) {
            //secondary nav below intro section - sticky secondary nav
            var secondaryNavOffsetTop = belowNavHeroContent.offset().top - secondaryNavigation.height() - mainHeader.height();

            if (previousTop >= currentTop) {
                //if scrolling up...
                if (currentTop < secondaryNavOffsetTop) {
                    //secondary nav is not fixed
                    mainHeader.removeClass('is-hidden');
                    secondaryNavigation.removeClass('fixed slide-up');
                    belowNavHeroContent.removeClass('secondary-nav-fixed');
                } else if (previousTop - currentTop > scrollDelta) {
                    //secondary nav is fixed
                    mainHeader.removeClass('is-hidden');
                    secondaryNavigation.removeClass('slide-up').addClass('fixed');
                    belowNavHeroContent.addClass('secondary-nav-fixed');
                }

            } else {
                //if scrolling down...
                if (currentTop > secondaryNavOffsetTop + scrollOffset) {
                    //hide primary nav
                    mainHeader.addClass('is-hidden');
                    secondaryNavigation.addClass('fixed slide-up');
                    belowNavHeroContent.addClass('secondary-nav-fixed');
                } else if (currentTop > secondaryNavOffsetTop) {
                    //once the secondary nav is fixed, do not hide primary nav if you haven't scrolled more than scrollOffset
                    mainHeader.removeClass('is-hidden');
                    secondaryNavigation.addClass('fixed').removeClass('slide-up');
                    belowNavHeroContent.addClass('secondary-nav-fixed');
                }

            }
        }


    };

    /**
     * Init Map
     */
    var init_maps = function () {

        var map = null;

        $('.map-acf').each(function () {

            // create map
            map = new_map($(this));

        });

    };

    /*
     *  new_map
     *
     *  This function will render a Google Map onto the selected jQuery element
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	$el (jQuery element)
     *  @return	n/a
     */

    var new_map = function ($el) {

        // var
        var $markers = $el.find('.marker');


        // vars
        var args = {
            zoom: 16,
            center: new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false
        };


        // create map
        var map = new google.maps.Map($el[0], args);


        // add a markers reference
        map.markers = [];


        // add markers
        $markers.each(function () {

            add_marker($(this), map);

        });


        // center map
        center_map(map);


        // return
        return map;

    };

    /*
     *  add_marker
     *
     *  This function will add a marker to the selected Google Map
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	$marker (jQuery element)
     *  @param	map (Google Map object)
     *  @return	n/a
     */

    var add_marker = function ($marker, map) {

        // var
        var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

        // create marker
        var marker = new google.maps.Marker({
            position: latlng,
            map: map
        });

        // add to array
        map.markers.push(marker);

        // if marker contains HTML, add it to an infoWindow
        if ($marker.html()) {
            // create info window
            var infowindow = new google.maps.InfoWindow({
                content: $marker.html()
            });

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function () {

                infowindow.open(map, marker);

            });
        }

    };

    /*
     *  center_map
     *
     *  This function will center the map, showing all markers attached to this map
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	map (Google Map object)
     *  @return	n/a
     */

    var center_map = function (map) {

        // vars
        var bounds = new google.maps.LatLngBounds();

        // loop through all markers and create bounds
        $.each(map.markers, function (i, marker) {

            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

            bounds.extend(latlng);

        });

        // only 1 marker?
        if (map.markers.length == 1) {
            // set center of map
            map.setCenter(bounds.getCenter());
            map.setZoom(16);
        }
        else {
            // fit to bounds
            map.fitBounds(bounds);
        }

    };


    /**
     * Public
     */
    return {
        init: init
    };

};


// Create mainScript object
window.k = window.k || new mainScript();
console.log('Main Script');      //, k);
dh.ready(k.init);